//** App.tsx - WEB ***//
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import 'antd/dist/antd.css';
import "./App.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 
import 'react-calendar/dist/Calendar.css';

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

class App extends Component {

  render() {
    return (
      <>
        <WebRoutes />
      </>
    );
  }
}

export default App;
