import React from "react";
// Customizable Area Start
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select as SelectBox} from "antd";
import DoctorScheduleController from "./DoctorScheduleController.web";
import { AiOutlineCheckCircle, AiFillCaretUp, AiFillCaretDown } from "react-icons/ai";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
import { Link } from 'react-router-dom';
import DoctorProfilePopup from "../../../components/src/DoctorProfilePopup.web";
import { dummy } from "../../../blocks/appointmentmanagement/src/assets";
import moment from "moment";
import Calendar from 'react-calendar';
let config = require('../../../framework/src/config.js').baseURL;
import Loader from "../../../components/src/Loader.web";
import  Select from "react-select";
import { styled } from "@material-ui/core";
// Customizable Area End

export class DoctorSchedule extends DoctorScheduleController {

  // Customizable Area Start
  renderPageNavbar = () => {
    return (
      <div className="pageContainer">
        <ul className="DoctorDashboardHomePageNavbar-list">
          <li className="DoctorDashboardHomePageNavbar-list-item">
            <Link to="/doctorhome">
              <p>Home</p>
            </Link>
          </li>
          <li className="DoctorDashboardHomePageNavbar-list-item">
            <Link to="/doctorschedule">
              <p className="activeColor">Schedule</p>
            </Link>
          </li>
          <li className="DoctorDashboardHomePageNavbar-list-item">
          <Link to="/addpatient"><p>Add Patient</p></Link>
          </li>
          <li className="DoctorDashboardHomePageNavbar-list-item">
            <p>Chats</p>
          </li>
        </ul>
      </div>
    );
  };
  
  renderHealthSelect = () => {
    return (
      <Select
        data-test-id="healthId"
        isClearable={true}
        isSearchable={true}
        placeholder="Search to Select"
        onInputChange={this.handleHealthId}
        onChange={(data: any) => { this.handleSelectId(data?.value,data?.number) }}
        options={this.state.patientHealthId !== "" ? this.state.healthIdData.data : []}
        noOptionsMessage={() => "No Data Found"}
      />
    )
  };

  // Customizable Area End

  // Customizable Area Start
  renderAppointments = (time: string) => {
    return (
      <>
        <h2 className="appointments-time-heading">{time}</h2>
        <div className={this.state?.selectAppointmentDateData?.length == 0 ? "appointments-cards-container-lengthzero" : "appointments-cards-container"}>
          {this.state?.selectAppointmentDateData?.length == 0 ?
            <div className="noAppointments">
              <p> No appointments found </p>
            </div>
            : this.state.selectAppointmentDateData.map((item: any, index: number) => {
              return (
                <div
                  key={index}
                  className="appointments-card-main appointments-card-main-paymentDone">
                  <Row onClick={() => this.handleAppointmentsDetails(item.id)} data-test-id="appointmentsId">
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <div className="appointments-card-left">
                        <h4
                          className="appointments-card-location paymentLocationDone">
                          {item.attributes.mode_type} APPT.
                        </h4>
                        <div className="appointments-card-profile-main">
                          <div className="appointments-card-img-container">
                            {
                              item.attributes?.patient_profile_image ? <img src={config + item.attributes?.patient_profile_image} alt="" /> : <img src={dummy} alt="" />
                            }
                          </div>
                          <div className="appointments-card-details-container">
                            <h3 className="appointments-card-name">
                              {item.attributes.patient_full_name}
                            </h3>
                            <h3 className="appointment-age-gender-container">
                              <span className="appointments-card-gender">
                                {item.attributes.patient_gender == "Male" ? "M" : "F"}
                              </span>
                              <span className="add-vertical-line"></span>
                              <span className="appointments-card-age">
                                {item.attributes.patient_year}
                              </span>
                            </h3>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <div
                        className="appointments-card-center paymentDone">
                        <div>
                          <AiOutlineCheckCircle className="paymentMessage-icon" />
                          Payment Verified
                        </div>
                      </div>
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={8}>
                      <div className="appointments-card-right">
                        <h4 className="patientType">{item.attributes.type}</h4>
                        <h4 className="appointment-time">{item.attributes.time_slot} : {moment(item.attributes.time_slot, "hh:mm A").add(30, "minutes").format("hh:mm A")}</h4>
                      </div>
                    </Col>
                  </Row>
                </div>
              )
            })}
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderAddNewScheduleModal = () => {
    let children = this.state.symptomsData;
    const { Option } = SelectBox;
    return (
      <div>
        <Modal
          open={this.state.showAddNewScheduleModal}
          width={1000}
          footer={false}
          centered
          className="doctor-schedule-modal"
          onCancel={this.handleScheduleModal}
        >
          <div className="doctor-schudule-modal">
            <h2 className="doctor-schudule-modal-heading" data-test-id="scheduleText">Add New Appointment</h2>
            <Form
              data-test-id="addnewscheduleformId"
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              className="doctor-schudule-modal-form"
              onFinish={this.onAddNewSchedule}
              ref={this.formRef}
              fields={[
                {
                  name: ["doctorname"],
                  value: this.state.doctorFullName,
                },
                {
                  name: ["doctornumber"],
                  value: this.state.doctorNumber,
                },
                {
                  name: ["patientnumber"],
                  value: this.state.selectedHealthId ? this.state.selectPatientNumber : "",
                },
                {
                  name: ["modeSelect"],
                  value: this.state.modeType,
                }
            ]}
            >
              <h3 className="doctor-schedule-modal-section-heading">
                Patient Details
              </h3>
              <Row className="patientDetailsRow">
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="doctor-schudule-modal-form-item">
                    <Form.Item
                      name="healthid"
                      label="Health ID : "
                      rules={[
                        {
                          required: true,
                          message: "Please select health id"
                        }
                      ]}
                    >
                      {this.renderHealthSelect()}
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="doctor-schudule-modal-form-item">
                    <Form.Item
                      name="patientnumber"
                      label="Phone Number :"
                    >
                      <Input readOnly placeholder="Please select health id first" />
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="doctor-schudule-modal-form-item">
                    <Form.Item
                      name="symptoms"
                      label="Symptoms :"
                      rules={[
                        {
                          required: true,
                          message: "Please select symptoms"
                        }
                      ]}>
                      <SelectBox
                        data-test-id="symptomsChangeId"
                        mode="tags" style={{ width: '100%' }}
                        tokenSeparators={[',']}
                        onChange={(value) => this.handleSymptomsChange(value)}
                        className="schdeuleSelect borderSymptoms"
                        maxTagCount={5}
                      >
                        {children?.map((item: { id: string, attributes: { symptom_name: string } }) => {
                          return (
                            <Option key={item?.attributes?.symptom_name}>{item?.attributes?.symptom_name}</Option>
                          )
                        })}
                      </SelectBox>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <h3 className="doctor-schedule-modal-section-heading">
                Doctor Details
              </h3>
              <Row>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="doctor-schudule-modal-form-item">
                    <Form.Item
                      name="doctorname"
                      label="Name :"
                      rules={[
                        {
                          required: true,
                          message: "Please enter doctor name"
                        },
                        {
                          pattern: /^[a-zA-Z. ]{3,30}$/,
                          message: "Please enter valid doctor name"
                        }
                      ]}
                    >
                      <Input name="doctorname" readOnly/>
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="doctor-schudule-modal-form-item">
                    <Form.Item
                      name="doctornumber"
                      label="Phone Number :"
                      rules={[
                        {
                          required: true,
                          message: "Please enter doctor number."
                        },
                        {
                          pattern: /^[6789]\d{9}$/,
                          message: "Please enter valid doctor number."
                        }
                      ]}
                    >
                      <Input name="doctornumber" readOnly/>
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                  <div className="doctor-schudule-modal-form-item">
                    <Form.Item
                      name="modeSelect"
                      label="Mode :"
                      rules={[
                        {
                          required: true,
                          message: "Please select mode type."
                        }
                      ]}
                    >
                      <SelectBox
                        data-test-id="modeOnChangeID"
                        className="schdeuleSelect"
                        onChange={this.changeSelectIcon}
                        placeholder="Select mode"
                        suffixIcon={
                          this.state.modeSelect ? (
                            <AiFillCaretDown />
                          ) : (
                            <AiFillCaretUp />
                          )
                        }
                        value={this.state.modeType}
                        style={{ width: "100%" }}
                        options={[
                          { value: "In - Clinic", label: "In - Clinic" },
                          { value: "Virtual", label: "Virtual" },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </Col>
                {
                  this.state.modeType === "Virtual" &&
                  <Col xxl={8} xl={8} lg={8} md={8} sm={12} xs={24}>
                    <div className="doctor-schudule-modal-form-item marginTop">
                      <Form.Item
                        name="categorytype"
                        label="Category Type :"
                        rules={[
                          {
                            required: true,
                            message: "Please select category type."
                          }
                        ]}
                      >
                        <SelectBox
                          data-test-id="categoryOnChangeID"
                          className="schdeuleSelect"
                          onChange={this.handleCategoryType}
                          placeholder="Select Category Type"
                          suffixIcon={
                            this.state.categoryType ? (
                              <AiFillCaretDown />
                            ) : (
                              <AiFillCaretUp />
                            )
                          }
                          style={{ width: "100%" }}
                          options={[
                            { value: "Text Consultation", label: "Text Consultation" },
                            { value: "Phone Consultation", label: "Phone Consultation" },
                            { value: "Video Consultation", label: "Video Consultation" }
                          ]}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                }
              </Row>
              <Row className="selectDateRow">
                <Col xxl={8} xl={8} lg={8} md={12} sm={24} xs={24}>
                  <div className="doctor-schedule-modal-calender">
                    <h4 className="selectDatetext">Select Date :</h4>
                    <Form.Item
                      name="dateAppoint"
                      rules={[
                        {
                          required: true,
                          message: "Please select appointment date."
                        }
                      ]}>
                      <DatePicker
                        data-test-id="appointmentDateId"
                        showToday={false}
                        placement="bottomRight"
                        placeholder="Select follow up dates"
                        onChange={this.handleScheduleDate}
                        disabledDate={this.disabledPastDate}
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                  <div className="doctor-schedule-modal-select-time">
                    <h4 className="selectDatetext">Select Time :</h4>
                    <div className="doctor-schedule-modal-select-time-shift-container">
                      {
                        this.state.pickValue.map((item: any, index: any) => {
                          return (
                            <div
                              data-test-id={"shoeTimeSlotTitleId" + index}
                              className={`${item.value === this.state.activeTimeZone ? "doctor-schedule-modal-select-time-shift-active" : "doctor-schedule-modal-select-time-shift"}`}
                              onClick={() => this.getTimeSlotShow(item.value)}
                            >
                              {item.value}
                            </div>
                          )
                        })
                      }
                    </div>
                    <div className="doctor-schedule-modal-select-time-shift-time-morning-container">
                      <div className="flexDiv">
                        {
                          this.state.getTimeSlotsData.map((item, index) => {
                            let newClass = item.is_available ? "doctor-schedule-modal-select-time-shift-time-time marginRightTime" : "doctor-schedule-modal-select-time-shift-time-time-noAvailable marginRightTime"
                            return (
                              <button
                                data-test-id={"getTimeSlotId" + index}
                                className={`${item.start_time === this.state.activeTimeSlot ? "doctor-schedule-modal-select-time-shift-time-time doctor-schedule-modal-select-time-shift-time-time-active marginRightTime" : newClass}`}
                                onClick={() => this.handleScheduleTime(item.start_time)}
                                disabled={!item.is_available}
                                type="button"
                              >
                                {item.start_time}
                              </button>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="doctor-schudule-modal-buttons-container">
                <Form.Item >
                  <Button
                    data-test-id="cancelmodalid"
                    className="doctor-schudule-modal-modal-btn btn-cancel"
                    onClick={() => this.handleScheduleModal()}
                  >
                    Cancel
                  </Button>
                </Form.Item >
                <Form.Item >
                  <Button
                    className="doctor-schudule-modal-modal-btn btn-add"
                    htmlType="submit"
                  >
                    Add
                  </Button>
                </Form.Item >
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <div>
        <NavbarCommonComponent data-test-id="showProfileHeaderId" handleProfile={this.showProfileDetailsHandler} />
        <DoctorProfilePopup data-test-id="handleLogoutId" profileData={this.state.showPopup} logoutFunctiuon={this.handleDocLogout} showProfile={this.handleDoctorDetailsPage} doctorDetailsData={this.state.doctorProfileDetailsData} handleEditProfile={this.handleEditDoctorProfile} handleClosePopup={this.handlePopupClose} />
        {this.renderPageNavbar()}
        <div className="doctorSchedule-main">
          <Row>
            <Col lg={18} md={18} sm={12} xs={12}>
              <div className="doctorSchedule-heading-left">
                <h1 className="appointmentscheduleheading">Appointments Schedule</h1>
                <h4 >Schedule for this week</h4>
              </div>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <div
                data-test-id="openFormModalId"
                className="doctorSchedule-heading-right"
                onClick={() =>
                  this.setState({
                    showAddNewScheduleModal: !this.state.showAddNewScheduleModal,
                  })
                }
              >
                <h3 data-test-id="addnewscheduleId" className="add-new-schedule-btn">Add New Appointment</h3>
              </div>
            </Col>
          </Row>
          <Row className="calendarContainer">
            <Col lg={17} md={15} sm={24} xs={24}>
              {this.renderAppointments("08:00AM-10:00PM")}
              {this.renderAddNewScheduleModal()}
            </Col>
            <Col lg={7} md={9} sm={15} xs={18}>
              <div className="doctorSchedule-calender-main">
                <Calendar
                  data-test-id="getAppointmentByDateID"
                  onChange={this.handleAppointmentByDate}
                />
              </div>
            </Col>
          </Row>
        </div>
        <Loader height={this.state.height} loading={this.state.is_Loader} />
        <FooterDoctor />
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DoctorSchedule;

const ErrorTag = styled("p")({
  color: "red",
  fontSize: "14px",
  margin: "0 !important"
});
  // Customizable Area End