import React from "react";

// Customizable Area Start
import {
  Modal,
  Container,
  Box,
  Button,
  Typography,
  Input,
  styled,
  Tab,
  Tabs,
  TextField,
  Divider
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import { PiChatsLight } from "react-icons/pi";
import { FiPhone } from "react-icons/fi";
import { IoVideocamOutline } from "react-icons/io5";
import { MdOutlinePhoneInTalk, MdMoreVert} from "react-icons/md";
import { GoDeviceCameraVideo } from "react-icons/go";
import { LuSend } from "react-icons/lu";
import FooterDoctor from "../../../components/src/FooterDoctor.web";
// Customizable Area End

import ChatController, { configJSON, IChat, Props } from "./ChatController";

export default class Chat extends ChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

   callusersData = [
    {
      CallId: 43453,
      name: "Bertram Stickland",
      profilePicture: "https://xsgames.co/randomusers/avatar.php?g=male",
      callTime: "10: 23 AM",
      callType: "Incoming Call",
      calDuration: "20 mins",
    }
  ];

  renderChatBox = () => {
    return (
      <HeaderChat>
        <LeftBox>
          <UserBox>
            <LeftuserBox>
              <StyledUserImg src="https://images.unsplash.com/photo-1485206412256-701ccc5b93ca?q=80&w=2094&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="jkjkjkj" />
              <NameBox>
                <UserNameTypography>
                 John Lee <CallIdSpan>#12345</CallIdSpan>
                </UserNameTypography>
                <Typography>Lorem ipsum dolor sit amet</Typography>
              </NameBox>
            </LeftuserBox>
            <RightUserBox>
              <Typography>12:00 PM</Typography>
              <MessageCountBox>3</MessageCountBox>
            </RightUserBox>
          </UserBox>
        </LeftBox>
        <RightBox>
          <ChatDetailContainer>
            <ChatContainerHeader>
              <UserBox>
                <LeftuserBox>
                  <StyledUserImg
                    src="https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt=""
                  />
                  <NameBox>
                    <UserNameTypography>Dr. Lorem ipsum dolor</UserNameTypography>
                    <DoctorStatusTypoGraphy>
                      <DoctorStatusindicator></DoctorStatusindicator>Online
                    </DoctorStatusTypoGraphy>
                  </NameBox>
                </LeftuserBox>
                <IconsBox>
                  <StyledPhoneInTalkOutlinedIcon />
                  <StyledVideocamOutlinedIcon />
                  <StyledMoreVertOutlinedIcon />
                </IconsBox>
              </UserBox>
            </ChatContainerHeader>
            <Divider />
            <ChatsBox></ChatsBox>
          </ChatDetailContainer>
          <TextFieldBox>
            <StyledTextField
              placeholder="Write a Message..."
              variant="standard"
              fullWidth
            />
            <SendIconBox>
              <StyledSendOutlinedIcon />
            </SendIconBox>
          </TextFieldBox>
        </RightBox>
      </HeaderChat>
    )
  };

  renderCallBox = () => {
    return (
      <Box>
          <Box>
            <CallUserBox>
              <CallLeftUserBox>
                <CallStyledUserImg src="https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                <CallNameBox>
                  <CallUserNameTypography>
                  John Lee <CallIdSpan>#0001</CallIdSpan>
                  </CallUserNameTypography>
                  <Typography>Outgoing Call</Typography>
                </CallNameBox>
              </CallLeftUserBox>
              <CallRightUserBox>
                  <CallTypographyGreen>Outgoing Call</CallTypographyGreen>
                <CallDurationTypography>12 mins 44 secs</CallDurationTypography>
              </CallRightUserBox>
            </CallUserBox>
            <Divider />
          </Box>
      </Box>
    )
  };

  renderVcallBox = () => {
    return (
      <Box>
          <Box>
            <VcallUserBox>
              <LeftUserBox>
                <VcallStyledUserImg src="https://images.unsplash.com/photo-1479936343636-73cdc5aae0c3?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                <VcallNameBox>
                  <VcallUserNameTypography>
                    John Lee <CallIdSpan>#2323</CallIdSpan>
                  </VcallUserNameTypography>
                  <Typography>09:30 PM</Typography>
                </VcallNameBox>
              </LeftUserBox>
              <VcallRightUserBox>
                  <VcallTypographyGreen>Incoming Video Call</VcallTypographyGreen>
                <VcallCallDurationTypography>17 mins 23 secs</VcallCallDurationTypography>
              </VcallRightUserBox>
            </VcallUserBox>
            <Divider />
          </Box>
      </Box>
    )
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
      <NavbarCommonComponent handleProfile={undefined} />
      <ContainerBox>
      <StyledTabs value={this.state.value} onChange={this.handletabItemChange} data-test-id="tabId">
          <StyledTab icon={<ChatIcon />} />
          <StyledTab icon={<PhoneIcon />} />
          <StyledTab icon={<VideoIcon />} />
        </StyledTabs>
          <ChatBox>
            {this.state.value === 0 && this.renderChatBox()}
            {this.state.value === 1 && this.renderCallBox()}
            {this.state.value === 2 && this.renderVcallBox()}
          </ChatBox>
        </ContainerBox>
      <FooterDoctor />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const ContainerBox = styled(Box)({
  width: "89%",
  margin: "0 auto"
});

const ChatBox = styled(Box)({
   marginBottom: "70px"
});

const StyledTabs = styled(Tabs)({
  backgroundColor: "#f3f9fe",
  display: "inline-block",
  borderRadius: "30px",

  "& .MuiTabs-indicator": {
    display: "none",
  },
  "& .Mui-selected": {
    background: "#5989bd",
    color: "white",
    borderRadius: "30px",
  }
});

const StyledTab = styled(Tab)({
  "&.Mui-selected": { color: "white" }
});

const ChatIcon = styled(PiChatsLight)({
   fontSize: "25px !important"
});

const PhoneIcon = styled(FiPhone)({
  fontSize: "25px !important"
});

const VideoIcon = styled(IoVideocamOutline)({
  fontSize: "25px !important"
});

const HeaderChat = styled(Box)({
  display: "flex",
  gap: "1%",
});

const LeftBox = styled(Box)({
  padding: 10,
  display: "flex",
  flexDirection: "column",
  width: "39%",
  gap: 10
});

const LeftuserBox = styled(Box)({ display: "flex", gap: 20 });

const StyledUserImg = styled("img")({
  borderRadius: "50%",
  height: "60px"
});

const UserBox = styled(Box)({ display: "flex", gap: 10, justifyContent: "space-between" });

const NameBox = styled(Box)({ display: "flex", flexDirection: "column", justifyContent: "center" });

const CallIdSpan = styled("span")({
  fontWeight: 700,
  color: "#5989bd"
});

const UserNameTypography = styled(Typography)({
  fontWeight: "bold",
});

const RightUserBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const MessageCountBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  width: "25px",
  height: "25px",
  background: "#5989bd",
  color: "white",
  alignSelf: "center",
  padding: 5,
});

const RightBox = styled(Box)({
  width: "60%",
  marginBlock: "-55px",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
});

const ChatDetailContainer = styled(Box)({
  background: "#f4f4f4",
  padding: "30px",
  borderRadius: 10,
  height: "70vh",
  display: "flex",
  flexDirection: "column",
});

const ChatContainerHeader = styled(Box)({
  marginBottom: "20px",
});

const DoctorStatusindicator = styled("span")({
  display: "inline-block",
  background: "green",
  height: "10px",
  width: "10px",
  borderRadius: "50%",
  marginRight: "10px",
});

const DoctorStatusTypoGraphy = styled(Typography)({
  fontSize: "14px",
});

const IconsBox = styled(Box)({
  display: "flex",
  gap: "15%",
  color: "#2596be",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "60px",
});

const StyledPhoneInTalkOutlinedIcon = styled(MdOutlinePhoneInTalk)({
  fontSize: "40px",
});

const StyledVideocamOutlinedIcon = styled(GoDeviceCameraVideo)({
  fontSize: "40px"
});

const StyledMoreVertOutlinedIcon = styled(MdMoreVert)({
  fontSize: "40px"
});

const ChatsBox = styled(Box)({
  flex: 1,
  overflowY: "auto",
  display: "flex",
  flexDirection: "column-reverse"
});

const TextFieldBox = styled(Box)({
  display: "flex",
  width: "100%",
  background: "white",
  alignSelf: "center",
  gap: "10px"
});

const StyledTextField = styled(TextField)({
  width: "90%",
  background: "#f4f4f4",
  textAlign: "center",
  padding: "20px",
  borderRadius: 10
});

const SendIconBox = styled(Box)({
  background: "#2596be",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  borderRadius: "50%"
});

const StyledSendOutlinedIcon = styled(LuSend)({
  color: "white",
  fontSize: "30px"
});

const CallStyledUserImg = styled("img")({
  borderRadius: "50%",
  height: "60px"
});

const CallUserBox = styled(Box)({ 
  padding: 10, 
  display: "flex", 
  justifyContent: "space-between" 
});

const CallLeftUserBox = styled(Box)({ 
  display: "flex", 
  gap: 10 
});

const CallNameBox = styled(Box)({ 
  display: "flex", 
  flexDirection: "column", 
  justifyContent: "center" 
});

const CallUserNameTypography = styled(Typography)({
  fontWeight: "bold"
});

const CallRightUserBox = styled(Box)({
  display: "flex",
  flexDirection: "column"
});

const CallDurationTypography = styled(Typography)({
  alignSelf: "flex-end"
});

const CallTypographyGreen = styled(Typography)({
  color: "#84bcb4"
});

const CallTypographyTomato = styled(Typography)({
  color: "#e68d84"
});

const VcallStyledUserImg = styled("img")({
  borderRadius: "50%",
  height: "60px"
});

const VcallUserBox = styled(Box)({ 
  padding: 10, 
  display: "flex",
  justifyContent: "space-between" 
});

const LeftUserBox = styled(Box)({ 
  display: "flex", 
  gap: 10 
});

const VcallNameBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center"
});

const VcallUserNameTypography = styled(Typography)({
  fontWeight: "bold"
});

const VcallRightUserBox = styled(Box)({
  display: "flex",
  flexDirection: "column"
});

const VcallCallDurationTypography = styled(Typography)({
  alignSelf: "flex-end"
});

const VcallTypographyGreen = styled(Typography)({
  color: "#84bcb4"
});

const VcallTypographyTomato = styled(Typography)({
  color: "#e68d84"
});
// Customizable Area End
